.stickyFilter {
  position: sticky;
  top: 0;

  display: flex;
  gap: 10px;
  background-color: white;
  padding: 5px;
  border-bottom: 1px solid lightgray;
}

/* used to allow display inside demo box*/
.containDemo {
  height: 400px;
  width: 2000px;
  overflow-y: scroll;
}
