@value blue, blueDark from "colors.module.css";

.custom {
  background-color: blue;
  border-color: blue;
}

.custom:hover {
  background: #00a1dc;
  border-color: #00a1dc;
}

.custom:active,
.custom:global(.Mui-active) {
  background-color: blueDark;
  border-color: blueDark;
}
