:root {
  --green: #73c06b;
  --greenLight: #b9e0b5;
  --greenDark: #60b258;
  --grey: #9e9e9e;
  --greyLight: #eeeeee;
  --greyDark: #979797;
  --blue: #00a9e0;
  --blueLight: #80d4f0;
  --blueDark: #0098d8;
  --danger: #e23140;
  --dangerLight: #f198a0;
  --dangerDark: #da2532;
  --warning: #ffbd00;
  --warningLight: #ffde80;
  --warningDark: #ffae00;
  --black: #212121;

  --primaryText: #212121;
  --secondaryText: #616161;
  --link: #1155cc;
  --hoverLink: #1a0dab;
  --visitedLink: #660099;
}

html {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}
*,
*::before,
::after {
  box-sizing: 'inherit';
}
body {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  color: var(--primaryText);
}

h1 {
  font-family: 'Liveramp sans', sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.22;
}
h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.25;
}
h3 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.3;
}
h4 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
}
h5 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
}
h6 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.14;
}

a {
  color: var(--link);
}
a:hover {
  color: var(--hoverLink);
}
a:visited {
  color: var(--visitedLink);
}

::selection {
  background-color: rgba(112, 194, 98, 0.2);
}
