@value green from "colors.module.css";

*:not(svg) {
  box-sizing: border-box;
}

:global(#root) {
  display: flex;
}

html {
  background-color: white;
  line-height: 1.5;
}

h1,
h2,
h3 {
  color: green;
  margin: 4px 0 8px 0;
}

h4,
h5,
h6 {
  margin: 6px 0 10px 0;
}

:global(.h2Branding) {
  font-family: 'Liveramp sans', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25;
}

:global(.h3Branding) {
  font-family: 'Liveramp sans', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.3;
}

:global(.h4Branding) {
  font-family: 'Liveramp sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.33;
}

:global(.subtitle1) {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

:global(.subtitle2) {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.43;
}

:global(.subtitle3) {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.33;
}

body {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

:global(.body2) {
  font-size: 0.875rem;
  line-height: 1.43;
  margin: 0;
}

:global(.body3) {
  font-size: 0.75rem;
  line-height: 1.33;
  margin: 0;
}

:global(.caption) {
  font-size: 0.625rem;
  line-height: 1.4;
}

code {
  background-color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 0.85rem;
  padding: 4px;
  color: #1e88e5;
  font-weight: 500;
}

a {
  color: green;
  text-decoration: none;
}
